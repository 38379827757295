import React from 'react';

import Layout from '../components/Layout';
import Classes from './404.module.css';
import Sidebar from '../components/Sidebar';
import Menubar from '../components/Menubar';

const IndexPage = () => (
  <Layout contnent="Error" title="Error">
    <Sidebar showTabs="false"></Sidebar>
    <div className="container-fluid p-0">
      <section>
        <header className={Classes.Header}></header>
      </section>
    </div>
    <div>
      <h1>Page not found!!!</h1>
    </div>
  </Layout>
);

export default IndexPage;
